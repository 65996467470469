import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import _ from 'lodash';

import {
  SimpleHeader, TextField, Form, FormActionsBtns, DetailCard, AsyncSelect, Select, Intl,
} from 'components';
import { required } from 'config/InputErrors';
import ConsultantService from 'api/Consultant';

export default ( { data, isLoading, submitForm } ) => (
  <>
    <SimpleHeader title="settings" />
    <Container fluid>
      <DetailCard isLoading={isLoading}>
        <Form
          initialValues={data}
          onSubmit={submitForm}
          showErrorsInFooter
          body={() => (
            <>
              <Row>
                <Col md={6}>
                  <TextField
                    field="expressCost"
                    label="expressCost"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={6}>
                  <TextField field="version" label="version" validate={required} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AsyncSelect
                    multiple
                    field="consultantTop1"
                    label="consultantTop1"
                    validate={( val ) => ( val && val.length <= 3 ? null : 'fieldError.maxTopConsultants' )}
                    loadOptions={ConsultantService.filterConsultantsByQuery}
                  />
                </Col>
                <Col md={6}>
                  <AsyncSelect
                    multiple
                    field="consultantTop2"
                    label="consultantTop2"
                    validate={( val ) => ( val && val.length <= 3 ? null : 'fieldError.maxTopConsultants' )}
                    loadOptions={ConsultantService.filterConsultantsByQuery}
                  />
                </Col>
              </Row>

              <h5 className="text-uppercase mt-4">
                <Intl id="chatConfigLabel" />
                {' '}
              </h5>
              <hr className="mt-0 mb-2" />
              <Row>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT1"
                    label="chatConfig.PARAMETER_CHAT1"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT2"
                    label="chatConfig.PARAMETER_CHAT2"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT3"
                    label="chatConfig.PARAMETER_CHAT3"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT4"
                    label="chatConfig.PARAMETER_CHAT4"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <Select
                    multiple
                    field="chatConfig.PARAMETER_CHAT5"
                    label="chatConfig.PARAMETER_CHAT5"
                    options={[
                      { id: 15, name: 15 },
                      { id: 30, name: 30 },
                      { id: 45, name: 45 },
                      { id: 60, name: 60 },
                      { id: 75, name: 75 },
                      { id: 90, name: 90 },
                      { id: 105, name: 105 },
                      { id: 120, name: 120 },
                    ]}
                    validate={required}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT6"
                    label="chatConfig.PARAMETER_CHAT6"
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="chatConfig.PARAMETER_CHAT7"
                    label="chatConfig.PARAMETER_CHAT7"
                    validate={required}
                    type="number"
                  />
                </Col>
              </Row>


              <h5 className="text-uppercase mt-4">
                <Intl id="rates" />
                {' '}
              </h5>
              <hr className="mt-0 mb-2" />
              <Row>
                <Col md={3}>
                  <TextField
                    field="rates[0].value"
                    label={_.get( data, 'rates[0].key' )}
                    translate={false}
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="rates[1].value"
                    label={_.get( data, 'rates[1].key' )}
                    translate={false}
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="rates[2].value"
                    label={_.get( data, 'rates[2].key' )}
                    translate={false}
                    validate={required}
                    type="number"
                  />
                </Col>
              </Row>
              <h5 className="text-uppercase mt-4">
                <Intl id="paymentRequests" />
                {' '}
              </h5>
              <hr className="mt-0 mb-2" />
              <Row>
                <Col md={3}>
                  <TextField
                    field="minPaymentAmount"
                    label="minPaymentAmount"
                    translate
                    validate={required}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    field="minDaysPaymentRequest"
                    label="minDaysPaymentRequest"
                    translate
                    validate={required}
                    type="number"
                  />
                </Col>
              </Row>
              <h5 className="text-uppercase mt-4">
                <Intl id="registerConsultantSetting" />
                {' '}
              </h5>
              <hr className="mt-0 mb-2" />
              <Row>
                <Col md={3}>
                  <TextField
                    field="urlConsultantRegister"
                    label="urlConsultantRegister"
                    translate
                    validate={required}
                    type="text"
                  />
                </Col>
              </Row>
            </>
          )}
          footer={(
            <FormActionsBtns />
          )}
        />
      </DetailCard>
    </Container>
  </>
);
